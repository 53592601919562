@charset "utf-8";
@import "setting";
@import url('https://fonts.googleapis.com/css?family=Teko');

#bg-video {
	position:fixed;
	left:50%;
	min-width: 100vw;
	min-height:100vh;
	overflow: hidden;
	z-index:-1;
	top: 50%;
	transform: translate(-50%,-50%);
	@include mq-down() {
		display: none;
	}
}

.video-bg_sp {
	display: none;
	@include mq-down() {
		display: block;
		width: 100vw;
		height:100vh;
		position:fixed;
		top: 0;
		left:0;
		z-index:-1;
		background:url("../video/video-bg_sp.png");
		background-size:cover;
		background-position: 72% 100%;
		background-repeat: no-repeat;
	}
}

.overlay-top {
	position: absolute;
	top: 0;
	left: 0;
	width:100%;
	height:100vh;
	background: $red;
	@include mq-down(sm) {
		display: none;
	}
}
.counter {
		font-family: 'Teko', sans-serif;
		margin: 40px auto;
		@include mq-down(sm) {
			margin: 10px auto;
		}
		.col-lg-3 {
			&:nth-of-type(2) {
				position: relative;
				&:before {
					content: '+';
					color: #fff;
					font-size: 80px;
					position: absolute;
					display: inline-block;
					top: 50%;
					left: 0;
					transform: translate(-50%,-50%);
					@include mq-down(md) {
						content: none;
					}
				}
				&:after {
					content: '=';
					color: #fff;
					font-size: 80px;
					position: absolute;
					display: inline-block;
					top: 50%;
					right: 0;
					transform: translate(50%,-50%);
					@include mq-down(md) {
						content: none;
					}
				}
			}
		}
	.count-box {
		width: 200px;
		height: 200px;
		border-radius: 50%;
		background: linear-gradient(white 0%,white 50%,#e6e6e6 50%,#e6e6e6 100%);
		margin: 0 auto;
		h3 {
			color: $red;
			font-weight: bold;
			padding: 4px 0px 4px 8px;
			font-size: 26px;
			margin: 5px 0 0 0;
			width: 100%;
			text-align: center;
			span.count {
				font-size: 80px!important;
			}
		}
		p {
			margin-top: -20px;
			font-weight: bold !important;
			font-size: 18px !important;
			color: #4D4D4D !important;
			text-align: center;
		}
		.ds {
			filter: drop-shadow(0px -2px 20px rgba(0, 0, 0, 0.4));
		}
		& + .sp-text {
			color: white;
			text-align: center;
			font-size: 15px;
			font-weight:bold;
			margin-top: 40px;
			margin-bottom: 40px;
		}
	}
	& + .container {
		.taC_L_s {
			margin-top: 40px;
		}
	}
}

.division-top {
	margin-bottom: 400px!important;
	@include mq-down(lg) {
		margin-bottom: 300px!important;
	}
	@include mq-down(md) {
		margin-bottom: 0px!important;
	}
}
